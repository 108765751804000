<template>
  <div class="my-5">
    <b-container class="shlajsna">
      <b-overlay :show="showOverlay">
      <b-row class="mb-5">
        <b-col cols="12" md="9" class="pr-4">
          <h1>{{ myTitle | removeLat }}</h1>

          <p>
            <!-- {{ mUser}} -->
            <b-avatar v-if="mUser.logo" :src="mUser.logo" size="1.75rem"/> 
            <b-link :to="{name: 'UserMedia',  params: { lang: this.$root.lang.shortName, userId: mUser.hash } } ">
              {{ mUser.enterpriseName | removeLat}} 
            </b-link>
              | {{ dateTime(m.createdAt, "medium") }}
          </p>
          <div v-html="myDesc"></div>
          <b-embed 
            type="video" 
            aspect="16by9" 
            controls 
            :poster="m.posterUrl" 
            preload="metadata" 
            :src="m.lowResUrl" 
            @play="playing" 
            oncontextmenu="return false;" 
            controlsList="nodownload">
              <track v-for="sub in m.subtitles" :key="sub.id" :label="sub.language" kind="subtitles" srclang="en" :src="sub.subtitleFilePathVtt"> 
          </b-embed>

          <b-row class="metaData my-4" v-if="myMetaData">
            <b-col cols="12" md="4">
              <div><span>{{ $t('videos.author')}}:</span> {{ m.author }}</div>
              <div><span>{{ $t('videos.source')}}:</span> {{ mUser.enterpriseName }}</div>
              <div><span>{{ $t('videos.year')}}:</span> {{ m.year }}</div>
              <div><span>{{ $t('videos.uploaded')}}:</span> {{ dateTime(m.createdAt, "medium") }} </div>
              
            </b-col>
            <b-col cols="12" md="4">
              <div><span>{{ $t('users.country')}}:</span> {{ mUser.country }}</div>
              <div><span>{{ $t('videos.duration')}}:</span> {{ secondsToTime(myMetaData.format.duration) }}</div>
              <div><span>{{ $t('videos.codec')}}:</span> {{ myMetaData.format.format_long_name }}</div>
              <div><span>{{ $t('videos.size')}}:</span> {{ getFileSize(myMetaData.format.size) }}</div>
            </b-col>
            <b-col cols="12" md="4">
              <div><span>{{ $t('videos.bit-rate')}}:</span> {{ getBitRate(myMetaData.format.bit_rate) }}</div>
              <div><span>{{ $t('videos.frame-rate')}}:</span> {{ myMetaData.streams[0].r_frame_rate }}</div>
              <div><span>{{ $t('videos.resolution') }}</span> {{ myMetaData.streams[0].width }}x{{ myMetaData.streams[0].height }}px</div>
              <div v-if="m.subtitles.length > 0">
                <span>{{ $t('videos.subtitle') }}:</span>
                <span v-for="sub in m.subtitles" 
                  :key="sub.id" 
                  @click="downloadSubtitle" 
                  :data-subtitleid="sub.id" 
                  class="subtitleItem" :title="$t('videos.download-subtitle')"> {{ sub.language }}</span>
              </div>
            </b-col>
          </b-row>
           <CategoriesSection :categories="m.categories" />
        </b-col>

        <b-col cols="12" md="3" class="pl-4 aside align-self-center">
          <h2>{{ $t('videos.download' )}}</h2>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="selectedDownload" :options="downloadOptions" :aria-describedby="ariaDescribedby"
              name="radio-btn-stacked" buttons stacked  button-variant="light" block>
            </b-form-radio-group>
          </b-form-group>
          <b-button block variant="primary" @click="download" size="lg">{{ $t('videos.download-video') }}</b-button>
        </b-col>
      </b-row>
      </b-overlay>

      <b-row  class="mt-4">
        <b-col cols="12">
          <h2>{{ $t('general.recently-added')}}</h2>
        </b-col>
        <b-col cols="12">
          <MediaGrid :media="media" type="public" />
        </b-col>
      </b-row>
      <b-row v-if="loadMore" class="mb-4">
        <b-col  cols="12">
          <p class="text-center">
            <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">{{ $t('general.load-more') }}</b-button>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BAvatar, BFormRadioGroup, BEmbed } from 'bootstrap-vue'
import MediaGrid from '@/components/util/MediaGrid.vue'
import CategoriesSection from '@/components/util/CategoriesSection.vue'

export default {
  name: "ViewVideo",
  components : { MediaGrid, BAvatar, BFormRadioGroup, BEmbed, CategoriesSection},
  data : function () {
    return {
      m : {},
      mUser: {},
      media: [],
      showOverlay: true,
      selectedDownload: 'fullres',
      downloadOptions: [
        { text: this.$t("videos.original-video"), value: 'fullres' },
        { text: this.$t("videos.preview-video"), value: 'lowres' }
      ],
      myMetaData:  null,
      played: false,
      loadMore: false,
      page: 0
    }
  },
  computed: {
    myTitle: function() {
      if (this.$root.lang.shortName === 'en') {
        return this.m.titleEn;
      } else {
        return this.m.title;
      }
    },
    myDesc: function() {
      if (this.$root.lang.shortName === 'en') {
        return this.m.descriptionEn;
      } else {
        return this.m.description;
      }
    },
    _lang: function() {
      return this.$root.lang
    }
  },
  created: async function() {
    this.setSingleVideoData();
    this.showOverlay = false;
    
    if (window.sessionStorage.getItem("singleVideoPagination")) {
      this.media = this.decodeJSON(window.sessionStorage.getItem("singleVideoPagination"));
      this.loadMore = window.sessionStorage.getItem("singleVideoPaginationHasNext");
      this.page = window.sessionStorage.getItem("singleVideoPaginationPage");
    } else {
     this.setRecentVideoData()
    }

  },
  methods: {
    playing: function() {
		  if (this.played === false) {
			  this.played = true;
			  this.$http.post('/api/media/ping/', this.m).then(r => {  
        // console.log(r);
       }).catch(e => { });
		  }
    },

    download: function() {
      var user = this.getAuthData();
      if (user === null) {
        this.$router.push({name : "Login"});
      } else {
        window.location = "/cms/api/media/download/" + this.m.id + "/" + this.selectedDownload;
      }
    },

    downloadSubtitle(event) {
      console.log(event.currentTarget.getAttribute("data-subtitleid"));
      const user = this.getAuthData();
      if (user === null) {
        this.$router.push({name : "Login"});
      } else {
        window.location = "/cms/api/media/download/subtitle/" + event.currentTarget.getAttribute("data-subtitleid");
        // console.log("/cms/api/download/subtitle/" + event.currentTarget.getAttribute("data-subtitleid"));
      }
    }, 

    loadMoreData: function() {
      this.page++;
      this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/video/?page=' + this.page).then(r => {
        this.media = this.media.concat(r.data.content);
        this.loadMore = r.data.hasNext;
        window.sessionStorage.setItem("singleVideoPagination", this.encodeJSON(this.media));
        window.sessionStorage.setItem("singleVideoPaginationHasNext", r.data.hasNext);
        window.sessionStorage.setItem("singleVideoPaginationPage", this.page);
      }).catch(e => {
        var message = this.handleError(e);
      })
    }, 
    async setSingleVideoData() {
      await this.$http.get('/api/media/get/' +  this.$route.params.videoId + '/' + this.$root.lang.IANA).then(r => {
        this.m = r.data;
        //console.log(JSON.parse(this.m.meta));
        this.myMetaData = JSON.parse(this.m.meta)
        this.mUser = r.data.createdBy;
        //console.log(this.m.user.enterpriseName);
        this.downloadOptions = [
          { text: this.$t("videos.original-video"), value: 'fullres' },
          { text: this.$t("videos.preview-video"), value: 'lowres' }
        ]
      }).catch(error => {
        var message = this.handleError(error);
      })
    },
    async setRecentVideoData() {
      await this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/video/?page=0').then(r => {
        this.media = r.data.content;
        this.loadMore = r.data.hasNext;
        this.showOverlay = false;
        }).catch(e => {
        var message = this.handleError(e);
        this.showOverlay = false;
      })
    }
  },
  metaInfo: function() {
    return {
      title: this.m.title,
      description: this.m.description
    }
  },
  watch:{
    _lang : async function() {
      this.setSingleVideoData();
      this.setRecentVideoData();
    }
  }

}
</script>

<style></style>
